<template>
  <div class="card-wrapper">
    <card
      v-for="(card, index) in cards"
      :key="index"
      :title="card.title"
      :subtitle="card.subtitle"
      :author="card.author"
      :videos="card.videos"
      :hours="card.hours"
      :image="card.image"
      :price="card.price"
      :edit="true"
    />
  </div>
</template>

<script>
import Card from '../components/Card.vue'
export default {
  components: { Card },
  name: 'MyCourses',
  data: () => ({
    cards: [
      {
        title: 'Full Vue.js development course',
        subtitle:
          'In this course you will create a commercial project using Vue, VueX, Router, Firebase',
        author: 'Jhon Silver',
        videos: 15,
        hours: 22,
        image:
          'https://timeweb.com/ru/community/article/1d/1d959c23e81024374895da086675b298.jpg',
        price: '300$',
      },
    ],
  }),
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  width: 75vw;
  margin: auto;
  display: flex;
  flex-direction: column;
}
</style>
